<template>
  <section>
    <header>
      <h1>{{ $t('profile.title') }}</h1>
      <u-icon
          name="nv-user-edit"
          @click="updateMode = true"
          v-if="!updateMode"
      />
    </header>

    <v-form ref="profileForm" v-model="valid" lazy-validation>
      <v-text-field
          :label="$t('profile.inputs.username')"
          v-model="userLocale.username"
          disabled
          outlined
          background-color="white"
      />
      <v-text-field
          :label="$t('profile.inputs.firstName')"
          v-model="userLocale.firstName"
          v-if="!isPatient"
          disabled
          outlined
          background-color="white"
      />
      <v-text-field
          :label="$t('profile.inputs.lastName')"
          v-model="userLocale.lastName"
          v-if="!isPatient"
          disabled
          outlined
          background-color="white"
      />
      <v-text-field
          type="email"
          :label="$t('profile.inputs.mail')"
          v-model="userLocale.email"
          :disabled="!updateMode"
          outlined
          background-color="white"
          @input="showEmail2()"
          ref="email"
      />
      <v-text-field
          type="email"
          :label="$t('profile.inputs.mailConfirm')"
          v-model="userLocale.email2"
          v-show="updateMode && email2IsVisible"
          :disabled="!updateMode"
          :rules="[rules.emailConfirm, rules.email2Required]"
          ref="email2"
          outlined
          background-color="white"
      />
      <v-select
          :label="$t('profile.inputs.profile')"
          :items="groupsLocale"
          v-model="userLocale.profile"
          disabled
          outlined
          background-color="white"
          multiple
      />
      <v-text-field
          :label="$t('profile.inputs.referent')"
          v-model="userLocale.referent"
          disabled
          outlined
          background-color="white"
          v-if="!isTechnician && !isAdmin"
      />
      <v-dialog
          ref="dialog"
          v-model="alarmModal"
          persistent
          width="290px"
          v-if="isPatient"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="userLocale.alarmTime"
              :label="$t('profile.inputs.alarmTime')"
              :disabled="!updateMode"
              outlined
              background-color="white"
              v-bind="attrs"
              v-on="on"
          />
        </template>
        <v-time-picker
            v-if="alarmModal"
            v-model="userLocale.alarmTime"
            full-width
            format="24hr"
        >
          <v-spacer/>
          <v-btn text color="primary" @click="alarmModal = false">
            {{ $t('general.buttons.cancel') }}
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(userLocale.alarmTime)"
          >
            {{ $t('general.buttons.validate') }}
          </v-btn>
        </v-time-picker>
      </v-dialog>
    </v-form>

    <aside>
      <div>
        <v-btn color="secondary" @click="onSubmit" v-if="updateMode">
          {{ $t('general.buttons.saveUpdate') }}
        </v-btn>
        <v-btn @click="cancelForm" v-if="updateMode">
          {{ $t('general.buttons.cancel') }}
        </v-btn>
      </div>
      <v-btn color="primary" @click="$router.push({ name: 'ResetPassword' })">
        {{ $t('general.buttons.resetPassword') }}
      </v-btn>
    </aside>
  </section>
</template>

<script>
import { JWTTranslation } from '@/store/modules/Security'
import { mapActions, mapGetters } from 'vuex'
import { humanProfile } from '@/store/modules/User'

export default {
  name: 'Profile',

  data: function () {
    return {
      alarmModal: false,
      userLocale: {
        id: null,
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        email2: '',
        profile: '',
        referent: '',
        alarmTime: '',
        group: '',
      },
      groupsLocale: [],
      updateMode: false,
      email2IsVisible: false,
      rules: {
        emailConfirm: v => v === this.userLocale.email || this.$t('profile.alert.sameEmail'),
        email2Required: v => !!v || this.$t('profile.alert.confirmEmail'),
      },
      valid: false,
    }
  },

  computed: {
    ...mapGetters(['groups', 'snackbarOptions', 'user']),
    isPatient () {
      return this.user.groups.find(group => group.name === 'PAT')
    },
    isTechnician () {
      return this.user.groups.find(group => group.name === 'TEC')
    },
    isAdmin () {
      return this.user.groups.find(group => group.name === 'ADM')
    },
  },

  created () {
    // TODO: Use the userconnected for the account
    Promise.all([
      this.loadGroups(),
      this.loadUser({
        id: JWTTranslation(localStorage.getItem('JWT_ACCESS')).body.userId,
      }),
    ])
           .then(() => {
             this.groupsLocale.splice(0, this.groupsLocale.length)

             this.groups.forEach(group => {
               this.groupsLocale.push({
                 value: group.id,
                 text: group.humanName,
               })
             })

             this.userLocale.id = this.user.id
             this.userLocale.username = this.user.username
             this.userLocale.firstName = this.user.firstName
             this.userLocale.lastName = this.user.lastName
             this.userLocale.email = this.user.email
             this.userLocale.email2 = this.user.email
             this.userLocale.profile = this.user.groups.map(group => group.id)
             this.userLocale.alarmTime = this.user.alarmTime
             this.userLocale.group = this.user.groups.name
             this.userBeforeUpdate = { ...this.user }

             if (this.user.referent) {
               const { referent } = this.user
               const isDoc = referent.groups.find(group => group.name === 'MED')
               this.userLocale.referent =
                   `${isDoc ? 'Dr. ' : ''}${referent.lastName} ${referent.firstName[0].toUpperCase()}.`
             }
           })
  },

  methods: {
    ...mapActions([
      'loadGroups',
      'loadReferent',
      'loadUser',
      'updateUser',
      'displaySnackbar',
    ]),
    onSubmit () {
      if (this.$refs.profileForm.validate()) {
        const { email, alarmTime } = this.userLocale
        this.updateUser({
          user: this.userLocale,
          data: this.isPatient ? { email, alarmTime } : { email },
        })
            .then(() => {
              this.userLocale.email = this.user.email
              if (this.isPatient) this.userLocale.alarmTime = this.user.alarmTime

              this.updateMode = false
              this.email2IsVisible = false
              this.userLocale.email2 = null

              this.displaySnackbar({
                status: true,
                type: 'info',
                text: this.isPatient
                      ? this.$t('profile.alert.okPatient')
                      : this.$t('profile.alert.ok'),
              })
            })
      }
    },
    onUpdate () {
      this.userLocale.id = this.user.id
      this.userLocale.username = this.user.username
      this.userLocale.firstName = this.user.firstName
      this.userLocale.lastName = this.user.lastName
      this.userLocale.email = this.user.email
      this.userLocale.profile = humanProfile(this.user.groups.name)
      // this.userLocale.referent = this.user.referent
      this.userLocale.alarmTime = this.user.alarmTime
    },
    showEmail2 () {
      this.userLocale.email2 = null
      this.email2IsVisible = true
    },
    cancelForm () {
      this.updateMode = false
      this.userLocale.email2 = null
      this.email2IsVisible = false
      Object.assign(this.userLocale, this.userBeforeUpdate)
      if (this.userBeforeUpdate !== null) {
        const { referent } = this.user
        const isDoc = referent ? referent.groups.find(group => group.name === 'MED') : false
        this.userLocale.referent = `${isDoc ? 'Dr. ' : ''}${referent.lastName} ${referent.firstName[0].toUpperCase()}.`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/backgroundPage";
@import "src/assets/style/style";

section {
  min-height: auto;

  header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    h1 {
      margin-bottom: 0 !important;
    }

    i {
      cursor: pointer;
      position: absolute;
      right: 0;
      font-size: 30px;
      color: $primaryColorDark;
    }
  }

  aside {
    > div:first-of-type {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      button {
        margin-left: 20px;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
      margin-top: 20px;
    }
  }

  form {
    .v-input::v-deep {
      label {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
